import React from "react"

import styled from "styled-components"

export function WhiteGap() {
  return <Gap></Gap>
}

const Gap = styled.div`
  width: 100%;
  height: 5vh;
  color: ${props => props.theme.color.white};
`
