import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import LogoLight from "../../images/icthub-light.png"
import LogoDark from "../../images/icthub-dark.png"
import {
  Menu,
  X,
  ChevronDown,
  Linkedin,
  Facebook,
  PhoneCall,
} from "react-feather"
import pages from "../../theme/pages"

const MenuListStyle = {
  open: {
    x: 8,
    opacity: 1,
    transition: {
      x: { stiffness: 800, velocity: -5 },
    },
  },
  closed: {
    x: -10,
    opacity: 0,
    transition: {
      x: { stiffness: 800 },
    },
  },
}

const variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
}
const navlink = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const QuickLinks = [
  {
    id: 11,
    name: "Home",
    page: "/",
  },
  {
    id: 12,
    name: "About us",
    page: pages.aboutUs,
  },
  {
    id: 13,
    name: "Contact us",
    page: pages.contactUs,
  },
  // {
  //   id: 14,
  //   name: "Blogs",
  //   page: pages.blogs,
  // },
  // {
  //   id: 15,
  //   name: "Case study",
  //   page: pages.caseStudy,
  // },
]
const Services = [
  {
    id: 16,
    name: "Voice & Mobility",
    page: pages.voiceMobility,
  },
  {
    id: 21,
    name: "Collabration",
    page: pages.collabration,
  },
  {
    id: 31,
    name: "Internet & Data",
    page: pages.internetData,
  },
]
const ITServices = [
  {
    id: 18,
    name: "Managed IT Services",
    page: pages.managedItServices,
  },
  {
    id: 22,
    name: "Managed Network",
    page: pages.managedNetwork,
  },
  {
    id: 23,
    name: "Cyber Security",
    page: pages.cyberSecurity,
  },
  {
    id: 5,
    name: "Cloud Services",
    page: pages.cloudServices,
  },
  {
    id: 26,
    name: "Digital Workplace",
    page: pages.digitalWorkplace,
  },
  {
    id: 51,
    name: "IT Procurement",
    page: pages.itProcurement,
  },
]

export function MobileNavbar({ bg }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isDrop, setIsDrop] = useState(true)

  return (
    <Cover bg={bg}>
      <Branding>
        <LogoLink to="/">
          <Logo src={bg ? LogoDark : LogoLight} alt="ICTHUB PTY LTD" />
        </LogoLink>
        <Burger bg={bg} onClick={() => setIsOpen(!isOpen)} />
      </Branding>

      <Overlay
        display={isOpen ? "block" : "none"}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <Cross onClick={() => setIsOpen(!isOpen)} />
        <Navigation animate={isOpen ? "open" : "closed"} variants={variants}>
          <NavLink
            padding="180%"
            size="1.8rem"
            weight="600"
            color={props => props.theme.color.matteWhite}
          >
            QuickLinks
          </NavLink>
          <LinkCover variants={navlink}>
            {QuickLinks.map((item, id) => (
              <MenuItem
                key={item.id}
                variants={MenuListStyle}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsOpen(!isOpen)}
              >
                <NavLink activeClassName="active" to={item.page}>
                  {item.name}
                </NavLink>
              </MenuItem>
            ))}
            <NavLink
              padding="280%"
              size="1.8rem"
              weight="600"
              color={props => props.theme.color.matteWhite}
            >
              Our Services
            </NavLink>

            {Services.map((item, id) => (
              <MenuItem
                key={item.id}
                variants={MenuListStyle}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsOpen(!isOpen)}
              >
                <NavLink activeClassName="active" to={item.page}>
                  {item.name}
                </NavLink>
              </MenuItem>
            ))}
            <MenuItem
              variants={MenuListStyle}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsDrop(!isDrop)}
            >
              <NavLink activeClassName="active">It Services</NavLink>
              <DropArrow />
            </MenuItem>
            <DropCover display={isDrop ? "block" : "none"}>
              {ITServices.map((item, id) => (
                <MenuItem
                  key={item.id}
                  variants={MenuListStyle}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <NavLink activeClassName="active" to={item.page}>
                    {item.name}
                  </NavLink>
                </MenuItem>
              ))}
            </DropCover>
          </LinkCover>
        </Navigation>

        <Social>
          <TitleCover>
            <NavLink
              padding="180%"
              size="1.8rem"
              weight="600"
              color={props => props.theme.color.matteWhite}
            >
              Follow us
            </NavLink>
            <MenuItem>
              <LinksIcon>
                <LinkId />
              </LinksIcon>
              <LinksIcon>
                <FaceBook />
              </LinksIcon>
            </MenuItem>
          </TitleCover>
          <TitleCover>
            <NavLink
              padding="180%"
              size="1.8rem"
              weight="600"
              color={props => props.theme.color.matteWhite}
            >
              Support & Sales
            </NavLink>
            <MenuItem>
              <LinksIcon href="tel:1300100428">
                <Call />
                1300 100 428
              </LinksIcon>
            </MenuItem>
          </TitleCover>
        </Social>
      </Overlay>
    </Cover>
  )
}

const LogoLink = styled(Link)``

const Social = styled.div`
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-between;
`

const LinksIcon = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.color.highlight};
  font-weight: 600;
  font-size: 3rem;
  line-height: 2.2rem;
  letter-spacing: 0.02em;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Cover = styled(motion.div)`
  display: none;
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 3000;
  background-color: ${props =>
    props.bg ? props => props.theme.color.white : ""};
  filter: ${props => props.theme.filter.shadow};
  @media only screen and (max-width: 75em) {
    display: block;
  }
  @media only screen and (max-width: 56.25em) {
    display: block;
  }
  @media only screen and (max-width: 37.5em) {
    display: block;
  }
  @media only screen and (min-width: 112.5em) {
    display: none;
  }
`

const DropArrow = styled(ChevronDown)`
  width: 3rem;
  height: 3rem;
  color: ${props => props.theme.color.white};
`
const Branding = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Logo = styled.img`
  width: 27rem;
  height: 100%;
`

const Burger = styled(Menu)`
  width: 5rem;
  height: 100%;
  color: ${props =>
    props.bg ? props => props.theme.color.black : props.theme.color.white};
`

const Overlay = styled(motion.div)`
  display: ${props => props.display};
  background-color: ${props => props.theme.color.black};
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  @media only screen and (max-width: 75em) {
    width: 50%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 50%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
`

const Cross = styled(X)`
  position: fixed;
  right: 1.5rem;
  top: 2rem;
  width: 5rem;
  height: auto;
  color: ${props => props.theme.color.highlight};
`

const Navigation = styled(motion.div)`
  padding: 5vh 0 3vh 2rem;
  width: 100%;
  height: auto;
`
const MenuItem = styled(motion.li)`
  display: flex;
  align-items: center;
  padding: 0.1rem 0;
  text-align: left;
`
const TitleCover = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.1rem 2rem;
  text-align: left;
`
const NavLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.color || props.theme.color.white};
  font-weight: ${props => props.weight || "500"};
  font-size: ${props => props.size || "2.5rem"};
  line-height: ${props => props.padding || "150%"};
  letter-spacing: 0.02em;
`
const LinkCover = styled(motion.ul)``

const DropCover = styled.ul`
  display: ${props => props.display};
  padding: 0.5rem 0 0 1rem;
`

const LinkId = styled(Linkedin)`
  margin: 1rem 0 2rem 0;
  width: 3rem;
  height: 3rem;
`
const FaceBook = styled(Facebook)`
  margin: 1rem 0 2rem 0;
  width: 3rem;
  height: 3rem;
`
const Call = styled(PhoneCall)`
  margin: 1rem 1rem 2rem 0;
  width: 3rem;
  height: 3rem;
`
