import React from "react"
import styled from "styled-components"
import { HeadingPrimary } from "../typography"

export function OfferSection({ children, title }) {
  return (
    <Cover>
      <HeadingPrimary>{title}</HeadingPrimary>
      <Wrapper>{children}</Wrapper>
    </Cover>
  )
}

const Cover = styled.section`
  padding: 3rem 0;
  text-align: center;
  background-color: ${props => props.theme.color.matteWhite};
`

const Wrapper = styled.div`
  margin: 2.5rem 0 0 0;
`
