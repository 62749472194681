import React from "react"
import styled from "styled-components"
import { HeadingPrimary } from "../typography"

import Fortinet from "../../images/brand/Fortinet_Logo_Black-Red.png"
import Grandstream from "../../images/brand/GrandStream.png"
import OKI from "../../images/brand/Horizontal_Business_Partner_Badge.png"
import Aruba from "../../images/brand/hpe_aruba_r_pos_rgb.png"
import HP from "../../images/brand/hpe_pri_grn_pos_rgb.png"
import Yealink from "../../images/brand/NEW-LOGO-2019.png"
import Panasonic from "../../images/brand/Panasonic Business 2 Lines (Transparent).png"
import Cisco from "../../images/brand/partner-logo.png"
import Sophos from "../../images/brand/sophos-global-partner-program-authorized.png"

export function Partners() {
  return (
    <Cover>
      <HeadingPrimary>Our Brand Partners</HeadingPrimary>
      <Wrapper>
        <LogoContainer>
          <Logo src={Fortinet} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={Grandstream} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={OKI} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={Aruba} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={HP} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={Yealink} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={Panasonic} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={Cisco} />
        </LogoContainer>
        <LogoContainer>
          <Logo src={Sophos} />
        </LogoContainer>
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.section`
  padding: 5rem 0;
  text-align: center;
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.color.matteWhite};
`

const Wrapper = styled.div`
  width: 80%;
  margin: 3.5rem auto 3.5rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* align-items: center; */
  @media only screen and (max-width: 75em) {
    width: 80%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 80%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 90%;
  }
`

const LogoContainer = styled.div`
  width: 15%;
  height: 60px;
  margin: 5rem 1rem;
  @media only screen and (max-width: 75em) {
    width: 20%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 20%;
  @media only screen and (max-width: 37.5em) {
    width: 24%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 15%;
  }
`

const Logo = styled.img`
  width: 100%;
  height: auto;
`
