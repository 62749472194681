import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { LightButton } from "../elements"
import { ArrowRight } from "react-feather"

export const Points = ({ children }) => (
  <PointCover>
    <Arrow />
    <Paragraph>{children}</Paragraph>
  </PointCover>
)

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        pattern: file(relativePath: { eq: "headers/patterns.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.pattern.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`rgba(20,20,20,1)`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

export function DetailSection({ points, paragraph1, paragraph2, children }) {
  return (
    <ImageCover>
      <Cover>
        <Wrapper>
          <Paragraph>{paragraph1}</Paragraph>
          {children}
          <Paragraph>{paragraph2}</Paragraph>
        </Wrapper>
        <LightButton
          borderColor={props => props.theme.color.highlight}
          to="/contact-us/"
        >
          Contact us
        </LightButton>
      </Cover>
    </ImageCover>
  )
}
const ImageCover = styled(BackgroundSection)`
  width: 100%;
  height: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
const Cover = styled.section`
  width: 100%;
  height: auto;
  min-height: 25rem;
  background-color: rgba(20, 20, 20, 0.8);
  padding: 5rem 1rem 5rem 1rem;
  text-align: center;
`

const Wrapper = styled.div`
  text-align: left;
  width: 45%;
  margin: 0 auto 4rem auto;
  @media only screen and (max-width: 75em) {
    width: 75%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 75%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
`
const Paragraph = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 170%;
  letter-spacing: 0.03em;
  color: ${props => props.theme.color.white};
  @media only screen and (max-width: 75em) {
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.8rem;
  }
  @media only screen and (min-width: 112.5em) {
    font-size: 1.8rem;
  }
`

const PointCover = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 2rem 0;
`

const Arrow = styled(ArrowRight)`
  color: ${props => props.theme.color.highlight};
  margin: 0 1rem 0 0;
`
