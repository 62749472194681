/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { GlobalStyle, theme } from "../theme/global-style"
import { ThemeProvider } from "styled-components"
import { MobileNavbar, Footer, Navbar, WhiteGap } from "./structure/"
import { CallAction, LoginButton, MailAction } from "./elements"
import { Partners } from "./sections/Partners"

const Layout = ({ children }) => {
  const [scrollViewport, setScrollViewport] = useState(false)
  function scrollMenu() {
    if (
      document.body.scrollTop > 250 ||
      document.documentElement.scrollTop > 250
    ) {
      setScrollViewport(true)
    } else {
      setScrollViewport(false)
    }
  }
  useEffect(() => {
    window.onscroll = function () {
      scrollMenu()
    }
  })
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Navbar bg={scrollViewport} />
        <MobileNavbar bg={scrollViewport} />
        <CallAction />
        <MailAction />
        <LoginButton />
        <main>{children}</main>
        <WhiteGap />
        <Partners />
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
