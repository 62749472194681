import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const theme = {
  font: {
    normal: "Montserrat, sans-serif",
    heading: "Raleway, sans-serif",
  },
  textsize: {
    links: "1.8rem",
    title: "2.0rem",
    catchyTitle: "2.2rem",
    smallTitle: "2.0rem",
    headerTitle: "4.8rem",
    headingPrimary: "3.2rem",
    subHeading: "2.4rem",
    headerHighlight: "2.0rem",
    paragraphs: "2.0rem",
    midParagraph: "2.4rem",
    smallParagraph: "1.8rem",
    tinyText: "1.2rem",
  },
  color: {
    highlight: "#2ADBEF",
    white: "#FFFFFF",
    black: "#000000",
    // black:  "rgba(20,20,20,1)",
    matteBlack: "#000000",
    // matteBlack: "rgba(20,20,20,0.85)",
    menuBlack: "#000000",
    // menuBlack: "rgba(0,0,0,0.95)",
    matteWhite: "#FFFFFF",
    // matteWhite: "rgba(249,248,248,0.5)",
    blurBlack: "rgba(0,0,0,.25)",
  },
  svgcolor: {
    bright: "#2ADBEF",
    dark: "rgba(20,20,20,0.2)",
    light: "rgba(255,255,255,1)",
  },
  filter: {
    overlay: "rgba(20,20,20,0.3)",
    boxShadow: "0px 4px 4px rgba(0,0,0,.25)",
    blurFilter: "blur(104px)",
    shadow: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))",
  },
  border: {
    buttonRadius: "0.5rem",
    color: " 0.2px solid rgba(0, 0, 0, 0.25);",
  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.normal};
    font-size: 62.5%;
    color: ${({ theme }) => theme.color.black};
    @media only screen and (max-width: 75em) {
      font-size: 52%;
      
    }
    @media only screen and (max-width: 56.25em) {
      font-size: 47.5%;
      
    } 
    @media only screen and (max-width: 37.5em) {
      font-size: 45%;
      
    } 
     @media only screen and (min-width: 112.5em) {
      font-size: 55%;
    } 
  }
  body {
    line-height: 1.35;
    letter-spacing: 0;
   
  }
  
  h1{
    font-family: ${({ theme }) => theme.font.heading};
  
  }
  h2 {
    font-family: ${({ theme }) => theme.font.normal};
  }
  p{
    font-family: ${({ theme }) => theme.font.normal};
   
  }
  a{
    font-family: ${({ theme }) => theme.font.normal};

    &.active{
      color:${({ theme }) => theme.color.highlight};
    }
    &:active{
      color:${({ theme }) => theme.color.highlight};
    }
    &:hover{
      color:${({ theme }) => theme.color.dark};
      opacity:0.9;
    }
  }
`
