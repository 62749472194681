import React from "react"
import styled from "styled-components"

export function HeadingPrimary({ children }) {
  return <Text>{children}</Text>
}

const Text = styled.h1`
  text-transform: capitalize;
  font-weight: bold;
  font-size: ${props => props.theme.textsize.headingPrimary};
  line-height: 135%;
  letter-spacing: 0.05em;
  color: ${props => props.color || props.theme.color.black};
  @media only screen and (max-width: 75em) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 3rem;
  }
`
