import React from "react"
import styled from "styled-components"

export function CatchyTitle({ children }) {
  return <Text>{children}</Text>
}

const Text = styled.h2`
  font-weight: bold;
  font-size: ${props => props.theme.textsize.catchyTitle};
  line-height: 150%;
  letter-spacing: 0.07em;
  color: ${props => props.color || props.theme.color.highlight};
`
