import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { LightButton } from "../elements"

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        pattern: file(relativePath: { eq: "headers/patterns.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.pattern.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`rgba(20,20,20,1)`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

export function BlackSection({ title, padDoc }) {
  return (
    <ImageCover padDoc={padDoc}>
      <Cover padDoc={padDoc}>
        <Wrapper>
          <Title>{title}</Title>
          {!padDoc && (
            <LightButton
              borderColor={props => props.theme.color.highlight}
              to="/contact-us/"
            >
              Contact us
            </LightButton>
          )}
        </Wrapper>
      </Cover>
    </ImageCover>
  )
}
const ImageCover = styled(BackgroundSection)`
  width: 100%;
  height: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
const Cover = styled.section`
  width: 100%;
  height: auto;
  min-height: 25rem;
  background-color: rgba(20, 20, 20, 0.75);
  padding: ${props =>
    props.padDoc ? "15rem 1rem 5rem 1rem" : "05rem 1rem 5rem 1rem"};
  text-align: center;
`

const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto 0 auto;
  @media only screen and (max-width: 75em) {
    width: 75%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 75%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }
`
const Title = styled.h1`
  text-transform: capitalize;
  font-weight: 700;
  font-size: 3rem;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 0 0 3rem 0;
`
