import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { Linkedin, Facebook } from "react-feather"
import LogoLight from "../../images/icthub-light.png"
import pages from "../../theme/pages"
import { motion } from "framer-motion"

const QuickLinks = [
  {
    id: 1,
    name: "Important Documents",
    page: pages.impDocs,
  },
  {
    id: 2,
    name: "Critical Information Summary",
    page: pages.criDoc,
  },
  // {
  //   id: 4,
  //   name: "Blogs",
  //   page: pages.blogs,
  // },
  // {
  //   id: 5,
  //   name: "Case study",
  //   page: pages.caseStudy,
  // },
]
const Services = [
  {
    id: 1,
    name: "Voice & Mobility",
    page: pages.voiceMobility,
  },
  {
    id: 2,
    name: "Collabration",
    page: pages.collabration,
  },
  {
    id: 3,
    name: "Internet & Data",
    page: pages.internetData,
  },
]
const ITServices = [
  {
    id: 1,
    name: "Managed IT Services",
    page: pages.managedItServices,
  },
  {
    id: 2,
    name: "Managed Network",
    page: pages.managedNetwork,
  },
  {
    id: 3,
    name: "Cyber Security",
    page: pages.cyberSecurity,
  },
  {
    id: 5,
    name: "Cloud Services",
    page: pages.cloudServices,
  },
  {
    id: 6,
    name: "Digital Workplace",
    page: pages.digitalWorkplace,
  },
  {
    id: 7,
    name: "IT Procurement",
    page: pages.itProcurement,
  },
]

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        pattern: file(relativePath: { eq: "headers/patterns.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.pattern.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`rgba(20,20,20,1)`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)
export function Footer() {
  return (
    <ImageCover>
      <Cover>
        <Wrapper>
          <Branding>
            <Logo src={LogoLight} alt="ICTHUB PTY LTD" />
            <Social>
              <TitleCover>
                <NavLink
                  padding="180%"
                  size="1.8rem"
                  weight="600"
                  color={props => props.theme.color.matteWhite}
                >
                  Follow us
                </NavLink>
                <IconCover>
                  <LinksIcon>
                    <LinkId />
                  </LinksIcon>
                  <LinksIcon>
                    <FaceBook />
                  </LinksIcon>
                </IconCover>
              </TitleCover>
            </Social>
          </Branding>

          <ContentCover>
            <LeftPart>
              <LeftCover>
                <TitleCover>
                  <NavLink
                    padding="180%"
                    size="1.8rem"
                    weight="600"
                    color={props => props.theme.color.matteWhite}
                  >
                    Sales & Support
                  </NavLink>
                  <MenuItem>
                    <NavLink href="tel:1300100428">1300 100 ICT</NavLink>
                  </MenuItem>
                </TitleCover>
                <TitleCover>
                  <NavLink
                    padding="180%"
                    size="1.8rem"
                    weight="600"
                    color={props => props.theme.color.matteWhite}
                  >
                    Email
                  </NavLink>
                  <MenuItem>
                    <NavLink href="mailto:info@icthub.com.au">
                      info@icthub.com.au
                    </NavLink>
                  </MenuItem>
                </TitleCover>
              </LeftCover>
              <LeftCover>
                <TitleCover>
                  <NavLink
                    padding="180%"
                    size="1.8rem"
                    weight="600"
                    color={props => props.theme.color.matteWhite}
                  >
                    Melbourne
                  </NavLink>
                  <MenuItem>
                    <NavLink>
                      Level 1, 80-82 Bell Street,
                      <br />
                      Heidelberg Heights,
                      <br />
                      VIC 3081
                    </NavLink>
                  </MenuItem>
                </TitleCover>
                <TitleCover>
                  <NavLink
                    padding="180%"
                    size="1.8rem"
                    weight="600"
                    color={props => props.theme.color.matteWhite}
                  >
                    Sydney
                  </NavLink>
                  <MenuItem>
                    <NavLink>
                      Suite 3, 55 Phillip Street,
                      <br />
                      Parramatta,
                      <br />
                      NSW 2150
                    </NavLink>
                  </MenuItem>
                </TitleCover>
              </LeftCover>
            </LeftPart>
            <RightPart>
              <TitleCover>
                <NavLink
                  padding="180%"
                  size="1.8rem"
                  weight="600"
                  color={props => props.theme.color.matteWhite}
                >
                  Quicklinks
                </NavLink>

                {QuickLinks.map((item, id) => (
                  <MenuItem>
                    <NavLink activeClassName="active" to={item.page}>
                      {item.name}
                    </NavLink>
                  </MenuItem>
                ))}
              </TitleCover>
              <TitleCover>
                <NavLink
                  padding="180%"
                  size="1.8rem"
                  weight="600"
                  color={props => props.theme.color.matteWhite}
                >
                  Telecommunication
                </NavLink>

                {Services.map((item, id) => (
                  <MenuItem>
                    <NavLink activeClassName="active" to={item.page}>
                      {item.name}
                    </NavLink>
                  </MenuItem>
                ))}
              </TitleCover>
              <TitleCover>
                <NavLink
                  padding="180%"
                  size="1.8rem"
                  weight="600"
                  color={props => props.theme.color.matteWhite}
                >
                  IT Services
                </NavLink>

                {ITServices.map((item, id) => (
                  <MenuItem>
                    <NavLink activeClassName="active" to={item.page}>
                      {item.name}
                    </NavLink>
                  </MenuItem>
                ))}
              </TitleCover>
            </RightPart>
          </ContentCover>
        </Wrapper>
        <Paragraph>
          ALL right resereved © {new Date().getFullYear()} ICTHUB PTY LTD
        </Paragraph>
      </Cover>
    </ImageCover>
  )
}

const LeftCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  @media only screen and (max-width: 75em) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  @media only screen and (max-width: 56.25em) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media only screen and (max-width: 37.5em) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const MenuItem = styled(motion.li)`
  display: flex;
  align-items: center;
  padding: 0.1rem 0;
  text-align: left;
`

const ContentCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const LeftPart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media only screen and (max-width: 75em) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  @media only screen and (max-width: 56.25em) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  @media only screen and (max-width: 37.5em) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const RightPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: 75em) {
    width: 95%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 95%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 65%;
  }
`

const LinksIcon = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.color.highlight};
  font-weight: 600;
  font-size: 3rem;
  line-height: 2.2rem;
  letter-spacing: 0.02em;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Social = styled.div`
  display: flex;
  justify-content: space-between;
`
const NavLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.color || props.theme.color.white};
  font-weight: ${props => props.weight || "400"};
  font-size: ${props => props.size || "1.3rem"};
  line-height: ${props => props.padding || "135%"};
  letter-spacing: 0.02em;
  @media only screen and (max-width: 75em) {
    font-size: ${props => props.size || "2.0rem"};
  }
  @media only screen and (max-width: 56.25em) {
    font-size: ${props => props.size || "2.0rem"};
  }
  @media only screen and (max-width: 37.5em) {
    font-size: ${props => props.size || "2.0rem"};
  }
`
const TitleCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem 0 0;
  text-align: left;
`
const IconCover = styled(TitleCover)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const LinkId = styled(Linkedin)`
  margin: 1rem 0 2rem 0;
  width: 3rem;
  height: 3rem;
`
const FaceBook = styled(Facebook)`
  margin: 1rem 0 2rem 0;
  width: 3rem;
  height: 3rem;
`

const Branding = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Logo = styled.img`
  width: 15rem;
  height: 100%;
`

const ImageCover = styled(BackgroundSection)`
  width: 100%;
  height: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
const Cover = styled.section`
  width: 100%;
  height: auto;
  min-height: 25rem;
  background-color: rgba(20, 20, 20, 1);
  padding: 1rem 1rem 0.5rem 1rem;
  text-align: center;
`

const Wrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  @media only screen and (max-width: 75em) {
    width: 95%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 95%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 65%;
  }
`

const Paragraph = styled.p`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 135%;
  letter-spacing: 0.03em;
  color: ${props => props.theme.color.white};
  padding: 3rem 0 0.5rem 0;
`
