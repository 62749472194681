import React from "react"
import styled from "styled-components"
import { DetailCard } from "../elements"
import { HeadingPrimary } from "../typography"

export function IntroSection({ src, content, title, margin }) {
  return (
    <Cover margin={margin}>
      <HeadingPrimary>{title}</HeadingPrimary>
      <Wrapper margin={margin}>
        <DetailCard src={src} content={content} />
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.section`
  padding: ${props => props.margin || "2.5rem  0"};
  text-align: center;
  background-color: ${props => props.theme.color.matteWhite};
`

const Wrapper = styled.div`
  margin: ${props => props.margin || "2.5rem 0 0 0"};
`
