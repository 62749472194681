import React from "react"
import styled from "styled-components"

export function HeaderTitle({ children }) {
  return <Text>{children}</Text>
}

const Text = styled.h1`
  font-weight: 700;
  font-size: ${props => props.theme.textsize.headerTitle};
  line-height: 135%;
  letter-spacing: 0.05em;
  color: ${props => props.color || props.theme.color.white};
  @media only screen and (max-width: 75em) {
    font-size: 4rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 4rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 4.5rem;
  }
`
