export default {
  voiceMobility: "/voice-and-mobility/",
  collabration: "/collabration/",
  managedNetwork: "/managed-network/",
  managedItServices: "/managed-it-services/",
  cyberSecurity: "/cyber-security/",
  itProcurement: "/it-procurement/",
  internetData: "/internet-and-data/",
  digitalWorkplace: "/digital-workplace/",
  cloudServices: "/cloud-services/",
  aboutUs: "/about-us/",
  contactUs: "/contact-us/",
  blogs: "/contact-us/",
  caseStudy: "/contact-us/",
  impDocs: "/important-documents/",
  criDoc: "/critical-information-summary/",
}
