import React, { useState } from "react"
import { ChevronDown, Facebook, Linkedin, PhoneCall } from "react-feather"
import { Link } from "gatsby"
import styled from "styled-components"

import LogoLight from "../../images/icthub-light.png"
import LogoDark from "../../images/icthub-dark.png"
import pages from "../../theme/pages"
import { motion } from "framer-motion"

const ITServices = [
  {
    id: 18,
    name: "Managed IT Services",
    page: pages.managedItServices,
  },
  {
    id: 22,
    name: "Managed Network",
    page: pages.managedNetwork,
  },
  {
    id: 23,
    name: "Cyber Security",
    page: pages.cyberSecurity,
  },
  {
    id: 5,
    name: "Cloud Services",
    page: pages.cloudServices,
  },
  {
    id: 26,
    name: "Digital Workplace",
    page: pages.digitalWorkplace,
  },
  // {
  //   id: 51,
  //   name: "IT Procurement",
  //   page: pages.itProcurement,
  // },
]
export function Navbar({ bg }) {
  const [overlayServices, setOverlayServices] = useState("none")

  function dropdownServices() {
    setOverlayServices("block")
  }
  function menuLinksShow() {
    setOverlayServices("none")
  }
  return (
    <NavCover bg={bg}>
      <Wrapper>
        <LogoSection to="/">
          <Logo src={bg ? LogoDark : LogoLight} alt="Icthub PTY LTD" />
        </LogoSection>
        <LinkContainer>
          <TopNav>
            <TopUl>
              <Links bg={bg} activeClassName="active" to="/">
                Home
              </Links>
              <Links bg={bg} activeClassName="active" to={pages.aboutUs}>
                About us
              </Links>
              <Links bg={bg} activeClassName="active" to={pages.contactUs}>
                Contact us
              </Links>
              {/* <Links bg={bg} activeClassName="active" to={pages.blogs}>
                Blogs
              </Links>
              <Links bg={bg} activeClassName="active" to={pages.caseStudy}>
                Case study
              </Links> */}
              <LinksIcon>
                <LinkId />
              </LinksIcon>
              <LinksIcon>
                <FaceBook />
              </LinksIcon>
            </TopUl>
          </TopNav>
          <BottomNav>
            <BottomUl>
              <Links bg={bg} activeClassName="active" to={pages.voiceMobility}>
                Voice & Mobility
              </Links>
              <Links bg={bg} activeClassName="active" to={pages.collabration}>
                Collabration
              </Links>
              <Links bg={bg} activeClassName="active" to={pages.internetData}>
                Internet & Data
              </Links>
              <LinksDrop
                bg={bg}
                activeClassName="active"
                onMouseOver={dropdownServices}
              >
                IT Services <DropArrow />
              </LinksDrop>
              <DropDownCover overlayServices={overlayServices}>
                <MenuLinks onMouseLeave={menuLinksShow}>
                  {ITServices.map((item, id) => (
                    <DesktopDropDownLinks
                      activeClassName="active"
                      to={item.page}
                    >
                      {item.name}
                    </DesktopDropDownLinks>
                  ))}
                </MenuLinks>
              </DropDownCover>
              <Links bg={bg} activeClassName="active" to={pages.itProcurement}>
                IT Procurement
              </Links>
              <LinksIcon href="tel:1300100428">
                <Call />
                1300 100 ICT
              </LinksIcon>
            </BottomUl>
          </BottomNav>
        </LinkContainer>
      </Wrapper>
    </NavCover>
  )
}
const DropDownCover = styled.div`
  display: ${props => props.overlayServices};
  margin: 22rem 0 0 42rem;
  position: fixed;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
`
const MenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const NavCover = styled(motion.div)`
  background-color: ${props =>
    props.bg ? props => props.theme.color.white : ""};
  padding: 1.5rem 4rem;
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 3000;
  @media only screen and (max-width: 75em) {
    display: none;
  }
  @media only screen and (max-width: 56.25em) {
    display: none;
  }
  @media only screen and (max-width: 37.5em) {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoSection = styled(Link)`
  width: 27rem;
  height: auto;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TopNav = styled.div`
  align-self: flex-end;
`

const BottomNav = styled.div``

const TopUl = styled.ul`
  padding: 0 0 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const BottomUl = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LinksIcon = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.color.highlight};
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.02em;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LinksDrop = styled(LinksIcon)`
  color: ${props =>
    props.bg ? props => props.theme.color.black : props.theme.color.white};
  font-size: 1.6rem;
  padding: 0 1rem 0 0;
`
const Links = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${props =>
    props.bg ? props => props.theme.color.black : props.theme.color.white};
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.02em;
  padding: 0 1.5rem 0 0;
  &:hover {
    color: ${props => props.theme.color.highlight};
  }
`
const DesktopDropDownLinks = styled(Links)`
  padding: 0.5rem 1.5rem 0 0;
  color: ${props =>
    props.bg ? props => props.theme.color.black : props.theme.color.black};
`

const Logo = styled.img`
  width: 100%;
  height: auto;
`

const LinkId = styled(Linkedin)``
const FaceBook = styled(Facebook)``
const Call = styled(PhoneCall)`
  margin: 0 1rem 0 0;
  width: 2rem;
  height: 2rem;
`

const DropArrow = styled(ChevronDown)``
